html,
body {
  margin: 0;
  padding: 0;
  color: rgb(72, 72, 72);
  font-size: 14px;
  font-weight: 300;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue', sans-serif;
}

@media print {
  // Hide all elements with no-print class
  .no-print,
  .no-print * {
    display: none !important;
  }

  // Expand, and hide the borders of any element with print-expand class
  .print-expand {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    box-shadow: none !important;
  }

  #jsd-widget {
    display: none !important;
  }
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPicker-Day--selected {
  position: relative;
  background-color: #4a90e2;
  color: #f0f8ff;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.big {
  font-size: 1.2rem;
}

.huge {
  font-size: 1.6rem;
}

.giant {
  font-size: 2rem;
}
.small {
  font-size: 0.9rem;
}

.micro {
  font-size: 0.7rem;
}

.display {
  font-family: Avenir;
}

.all-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.caps {
  text-transform: uppercase;
  letter-spacing: 0.07em !important;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

// Class styles

.pill {
  border-radius: 99999px;
}

.thick {
  font-weight: 400;
}

.thicker {
  font-weight: 500;
}

.thickest {
  font-weight: 600;
}

.quiet {
  opacity: 0.7;
}

.quieter {
  opacity: 0.5;
}

.quietest {
  opacity: 0.2;
}

.overflow-x-scroll {
  overflow-x: overlay;
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: overlay;
  overflow-x: hidden;
}

.default-cursor {
  cursor: default;
}
.pointer {
  cursor: pointer;
}

.crosshair {
  cursor: crosshair;
}

.hover-quiet:hover {
  opacity: 0.8;
}

.hover-quieter:hover {
  opacity: 0.7;
}

.hover-unquiet:hover {
  opacity: 1;
}

.hover-quiet,
.hover-quieter,
.hover-unquiet {
  transition: opacity 0.3s ease;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-thick {
  border-style: solid;
  border-width: 2px;
}

.border-bottom-thick {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.shadow-bottom {
  box-shadow: 0px 1px 0px 0px;
}

.shadow {
  box-shadow: 0px 0px 0px 1px;
}

.shadow-thick {
  box-shadow: 0px 0px 0px 2px;
}

.shadow-bottom-thick {
  box-shadow: 0px 2px 0px 0px;
}

.floating {
  box-shadow: 0 0 6px 2px hsla(0, 0%, 0%, 0.15);
}

.rounded-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rounded-bottom {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.calendarInput
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.calendarInput .DayPicker-Day {
  border-radius: 0 !important;
}

.ui.dropdown.site-selector > .dropdown.icon:before {
  content: none;
}

.ui.dropdown.site-selector > .dropdown.icon {
  height: 0px;
  width: 0px;
  border: none;
  border-top: 8px solid #000000;
  border-top-width: 8px;
  border-top-style: solid;
  border-top-color: rgb(0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  top: -3px;
  margin-left: 13px;
}

.arrow-down {
  height: 0px;
  width: 0px;
  border: none;
  border-top: 8px solid #000000;
  border-top-width: 8px;
  border-top-style: solid;
  border-top-color: rgb(0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  top: -3px;
  margin-left: 13px;
}

// Colors TODO: nathan
@mixin createColor($name, $hexCode) {
  .#{$name} {
    // important! is needed so that hover-dark1 doesn't mess with this
    background-color: $hexCode !important;
  }

  .text-#{$name} {
    color: $hexCode;
    fill: $hexCode;

    &.shadow-bottom {
      box-shadow: 0 1px 0 0 rgba($hexCode, 0.4);
    }
    &.shadow-bottom-thick {
      box-shadow: 0 2px 0 0 rgba($hexCode, 0.4);
    }
  }

  .border-#{$name} {
    border-color: $hexCode;
  }

  .shadow-#{$name} {
    &.shadow {
      box-shadow: 0 0 0 1px $hexCode;
    }
    &.shadow-thick {
      box-shadow: 0 0 0 2px $hexCode;
    }
  }
  //to detect semantic icon
  .#{$name}.icon {
    background-color: transparent !important;
  }
}

$deepBlue: #193441;
@include createColor('deepBlue', $deepBlue);

// ---

$blue: #3e606f;
@include createColor('blue', $blue);

$blue_light: lighten($blue, 20%);
@include createColor('blue_light', $blue_light);

$blue_lighter: lighten($blue, 30%);
@include createColor('blue_lighter', $blue_lighter);

$blue_lightest: lighten($blue, 50%);
@include createColor('blue_lightest', $blue_lightest);

$scottish_blue: #3d606f;
@include createColor('scottish_blue', $scottish_blue);

// ---

$green: #88a093;
// $green: #91aa9d;
@include createColor('green', $green);

$green_light: lighten($green, 10%);
@include createColor('green_light', $green_light);

$green_lighter: lighten($green, 20%);
@include createColor('green_lighter', $green_lighter);

// ---

$alert_green: #64c692;
@include createColor('alert_green', $alert_green);

// ---

$tan: #d1dbbd;
@include createColor('tan', $tan);

// ---

$manilla: #fcfff5;
@include createColor('manilla', $manilla);

// ---

$red: #c84253;
@include createColor('red', $red);

$red_light: lighten($red, 10%);
@include createColor('red_light', $red_light);

$red_lighter: lighten($red, 20%);
@include createColor('red_lighter', $red_lighter);

$red_lightest: lighten($red, 30%);
@include createColor('red_lightest', $red_lightest);

// ---

$yellow: #ffb03b;
@include createColor('yellow', $yellow);

$yellow_light: #ffc56e;
@include createColor('yellow_light', $yellow_light);

// @include createColor('orange', #f2711c);
@include createColor('white', #fff);

@include createColor('dark1', hsla(0, 0%, 0%, 0.1));
@include createColor('dark2', hsla(0, 10%, 19%, 0.25));
@include createColor('dark3', hsla(0, 0%, 0%, 0.4));

// Semantic overrides
.ui.celled.table {
  .right {
    float: none;
  }
}

.ui.fitted.toggle.checkbox.toggle {
  width: 20px;
  height: 12px;
  margin-top: 8%;
}
.ui.toggle.checkbox.toggle .box:before,
.ui.toggle.checkbox.toggle label:before {
  background-color: hsla(0, 10%, 19%, 0.25) !important;
  height: 12px;
  width: 20px;
}

.ui.toggle.checkbox.toggle input:checked ~ .box:before,
.ui.toggle.checkbox.toggle input:checked ~ label:before {
  background-color: hsla(0, 10%, 19%, 0.25) !important;
  width: 20px;
  height: 12px;
}
.ui.toggle.checkbox.toggle input:focus:checked ~ .box:before,
.ui.toggle.checkbox.toggle input:focus:checked ~ label:before {
  background-color: hsla(0, 10%, 19%, 0.25) !important;
}

.ui.toggle.checkbox.toggle input ~ .box:after,
.ui.toggle.checkbox.toggle input ~ label:after {
  background-color: $blue !important;
  width: 12px;
  height: 12px;
}
.ui.toggle.checkbox.toggle input:checked ~ .box:after,
.ui.toggle.checkbox.toggle input:checked ~ label:after {
  left: 12px;
}
.hover-dark1:hover {
  background-color: hsla(0, 0%, 0%, 0.05);
  transition: background-color 0.3s ease;
}

.hover-dark2:hover {
  background-color: hsla(0, 0%, 0%, 0.1);
  transition: background-color 0.3s ease;
}

// PADDING
.p-half {
  padding: 0.25rem;
}

.py-half {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-half {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pl-half {
  padding-left: 0.25rem;
}

.pr-half {
  padding-right: 0.25rem;
}

.pt-half {
  padding-top: 0.25rem;
}

.pb-half {
  padding-bottom: 0.25rem;
}

.m-half {
  margin: 0.25rem;
}

.my-half {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-half {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mxn-half {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.ml-half {
  margin-left: 0.25rem;
}

.mr-half {
  margin-right: 0.25rem;
}

.mt-half {
  margin-top: 0.25rem;
}

.mb-half {
  margin-bottom: 0.25rem;
}

.max-width-5 {
  max-width: 80rem;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

th {
  font-weight: 300;
  // padding-bottom: 5px;
}

td {
  padding-top: 2px;
  padding-bottom: 2px;
}

tbody {
  tr:nth-child(even) {
    background-color: #f4f4f4;
  }
}

.ui.inverted > .ui.modal > .close {
  color: #666;
}

// Spin animation
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:export {
  deep_blue: $deepBlue;
  blue: $blue;
  blue_light: $blue_light;
  blue_lighter: $blue_lighter;
  blue_lightest: $blue_lightest;
  scottish_blue: $scottish_blue;
  green: $green;
  green_light: $green_light;
  green_lighter: $green_lighter;
  tan: $tan;
  manilla: $manilla;
  red: $red;
  red_light: $red_light;
  red_lighter: $red_lighter;
  red_lightest: $red_lightest;
  yellow: $yellow;
  yellow_light: $yellow_light;
}
